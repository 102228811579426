import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import 'index.css'
import { Settings } from 'luxon'
import App from 'features/app/App'
import MobileApp from 'features/app/Mobile'
import * as serviceWorker from 'serviceWorker'
import configureStore from 'store/store'

import '@globalfishingwatch/ui-components/dist/base.css'

const store = configureStore()

Settings.defaultLocale = 'en'

const Root = () =>
  typeof window !== undefined && window.innerWidth > 720 ? (
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  ) : (
    <MobileApp />
  )

render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
